import axios from "axios";

// Definición de la URL base para las peticiones
const BASE_URL = "https://api.pd.nttdataco.com/ms-catalog";

const apiService = {
  getCategories: async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}/${"product"}`, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });

      if (response.status == 200) {
        let categories = apiService.sortCategories(
          response.data.serviceResponse
        );
        return categories;
      } else {
        return response.data.error.message;
      }
    } catch (error) {
      console.error("Error al hacer la petición POST:", error);
      throw error;
    }
  },
  sortCategories: (products) => {
    const categories = products.reduce((acc, product) => {
      if (!acc[product.category]) {
        // Si es la primera vez que encontramos esta categoría, la agregamos al objeto
        acc[product.category] = product;
      }
      return acc;
    }, {});

    // Extraemos las categorías únicas del objeto
    const categoriesUni = Object.keys(categories);

    // Creamos un array de objetos con las categorías y sus primeros productos
    const resultado = categoriesUni.map((category) => ({
      category,
      firstProd: categories[category],
    }));

    return resultado;
  },
};

export default apiService;
