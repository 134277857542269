import { navigateToUrl } from "single-spa";
//import { categories } from "../common/Dummy/categories";
import { useState, useEffect } from "react";
import "../Styles/FilterMenu.css";
import apiService from "../services/categories";

export default function FilterMenu() {
  const [category, setCategory] = useState("Todas");
  const [categories, setCategories] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  useEffect(() => {
    const getCategoriesApi = async () => {
      const jsonData = await apiService.getCategories({});
      setCategories([{ category: "Todas" }, ...jsonData]);
    };

    getCategoriesApi();
    window.addEventListener("total_products", (event: any) => {
      setTotalProducts(event.detail.total);
    });
  }, []);

  const handleSelectCategory = (id, name) => {
    setCategory(name);
    navigateToUrl(`?category=${id}`);
  };

  const formatName = (name) => {
    return name.replace("-", " ");
  };

  return (
    <div className="filter">
      <h1 className="section-title">Categoría</h1>
      <div className="menu-container">
        <h1 className="category-title">{formatName(category)}</h1>
        <h2 className="category-results">{totalProducts} resultados</h2>
        <div className="categories-list">
          <h3 className="list-title">Categorías</h3>
          <ul>
            {categories.map((item) => (
              <button
                key={item.category}
                id={item.category}
                onClick={() =>
                  handleSelectCategory(item.category, item.category)
                }
              >
                {formatName(item.category)}
              </button>
            ))}
          </ul>
          {/* <ul>
            {categories.map((item, index) => (
              <button
                key={index}
                onClick={() => handleSelectCategory(index.id, item.name)}
              >
                {item.name}
              </button>
            ))}
          </ul> */}
        </div>
        <div className="prices-filter-list">
          <h3 className="list-title">Precio</h3>
          <ul>
            <li>Hasta $100.000</li>
            <li>De $100.000 a $500.000</li>
            <li>Más de $500.000</li>
          </ul>
        </div>
        <div className="disponibility-filter-list">
          <h3 className="list-title">Disponibilidad</h3>
          <ul>
            <li>De 0 a 5</li>
            <li>De 6 a 10</li>
            <li>Mas de 10</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
